import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout/desktop/Layout';
import { Protected } from '@components/auth';
import { IsNotDesktop } from '@components/layout/desktop/IsNotDesktop';

const Instituicoes = loadable(() => import('@desktop/instituicoes/Instituicoes'));

function Index(props) {
  return (
    <Protected>
      <IsNotDesktop>
        <Layout {...props}>
          <Instituicoes {...props} />
        </Layout>
      </IsNotDesktop>
    </Protected>
  );
}

export default Index;
